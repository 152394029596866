@import url('https://fonts.googleapis.com/css2?family=Public+Sans:ital,wght@0,100..900;1,100..900&display=swap');
html,
body {
  font-family: "Public Sans", sans-serif;
  margin: 0;
}
// CSS for DataGrid ONCell Click Started
.cursor-pointer {
  cursor: pointer;
}
.ql-tooltip {
  left :0 !important
}
.expandable-quill-editor {
  width: 100%;
  border: 1px solid #ccc;
  border-radius: 4px;
  overflow: hidden;
  transition: height 0.2s;

}
.ql-toolbar.ql-snow {
  background-color: #ffffffde !important;

}
.ql-container {
  border: none;

}

.ql-editor {
  min-height: 300px;
  padding: 10px;
  outline: none;
}

.truncate-text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block; 
  max-width: 100%;
}

.truncate-placeholder::placeholder {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block; 
  max-width: 100%;
}

input:-webkit-autofill {
  box-shadow: 0 0 0px 1000px transparent inset !important;
  -webkit-text-fill-color: inherit !important;
  caret-color: inherit !important;
  border-radius: inherit !important;
}


